import React from 'react';
import alert from '../assets/svg/alert.svg';
import paymentCheck from '../assets/svg/special-check.svg';
import stripeBadge from '../assets/svg/stripe.svg';

const Icons = {
  alert,
  paymentCheck,
  stripeBadge
};

export const CaretDownIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      stroke={props.color || '#000'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m1 4 7 8 7-8"
    />
  </svg>
);

export default Icons;
