import React, { forwardRef, useState } from 'react';
import InputMask from 'react-input-mask';
import styles from './styles.module.scss';
import Icons from '../../util/icons';

export type textFieldProps = {
  autoComplete?: string;
  classes?: string[];
  className?: any;
  disabled?: boolean;
  errorMessage?: string;
  isError?: boolean;
  isRequired?: boolean;
  label?: string;
  mask?: string;
  maxLength?: number;
  name?: string;
  onBlur?: (event: any) => void;
  onChange?: (event: any) => void;
  onFocus?: (event: any, isFocused: boolean) => void;
  onKeyUp?: (event: any) => void;
  placeholder?: string;
  prefix?: string;
  type?: string;
  value?: string;
};

export const TextField = forwardRef<HTMLInputElement, textFieldProps>(
  (props: textFieldProps, ref) => {
    const {
      autoComplete,
      classes = [],
      className,
      disabled,
      errorMessage,
      isError,
      isRequired,
      label,
      mask = '',
      maxLength,
      name,
      onBlur,
      onChange,
      onFocus,
      onKeyUp,
      placeholder,
      prefix,
      type,
      value
    } = props;

    const id = `TextField-${name}`;
    const [fieldValue, setFieldValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);

    const handleOnFocus = (event: any) => {
      setIsFocused(true);
      if (onFocus) {
        onFocus(event, isFocused);
      }
    };

    const handleChange = (event: any) => {
      setFieldValue(event.target.value);
      if (onChange) {
        onChange(event);
      }
    };

    const handleBlur = (event: any) => {
      setIsFocused(false);
      if (onBlur) {
        onBlur(event);
      }
    };

    const handleKeyUp = (event: any) => {
      if (onKeyUp) {
        onKeyUp(event);
      }
    };

    const getFormatValue = (value: string, prefix?: string) => {
      if (prefix) value = value.replace(prefix, '');
      return value && prefix ? `${prefix}${value}` : value;
    };

    const inputValue = getFormatValue(value || fieldValue, prefix);

    const TextFieldTag = mask === '' ? 'input' : InputMask;

    const textFieldClass = inputValue.length
      ? `${styles.textField} ${styles['textField-filled']}`
      : `${styles.textField}`;
    const maskProps = mask && mask === '' ? {} : { mask };

    return (
      <div
        className={classes.join(' ')}
        style={{ display: type === 'hidden' ? 'none' : 'block', width: '100%' }}
      >
        {label && (
          <label className={styles.label} htmlFor={id}>
            {label}
          </label>
        )}
        <TextFieldTag
          autoComplete={autoComplete}
          className={[className, textFieldClass].join(' ')}
          disabled={disabled}
          id={id}
          maxLength={maxLength}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleOnFocus}
          onKeyUp={handleKeyUp}
          placeholder={placeholder}
          ref={ref}
          required={isRequired}
          type={type}
          value={inputValue}
          {...(maskProps as any)}
        />
        {isError && <img src={Icons.alert} alt="error" className={styles.textFieldAlert} />}
        {isError && <div className={styles.textFieldError}>{errorMessage}</div>}
      </div>
    );
  }
);
