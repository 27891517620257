/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { injectStripe, PaymentRequestButtonElement } from 'react-stripe-elements';

class PaymentRequestForm extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      captchaToken: null
    };

    // For full documentation of the available paymentRequest options, see:
    // https://stripe.com/docs/stripe.js#the-payment-request-object
    const paymentRequest = props.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Total',
        amount: Number(props.amt) * 100 || 0
      }
    });

    paymentRequest.on('token', ({ complete, token, ...data }) => {
      const { onSubmit } = this.props;

      onSubmit({ stripeToken: token })
        .then(() => {
          complete('success');
        })
        .catch((e) => {
          complete('fail');
        });
    });

    paymentRequest.canMakePayment().then((result) => {
      this.setState({ canMakePayment: !!result });
    });

    this.state = {
      canMakePayment: false,
      paymentRequest
    };
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    if (prevProps.amt !== this.props.amt && this.props.amt >= 0) {
      if (this.state.paymentRequest !== null && this.state.paymentRequest?.update) {
        this.state.paymentRequest.update({
          country: 'US',
          currency: 'usd',
          total: {
            label: 'Total',
            amount: Number(this.props.amt) * 100 || 0
          }
        });
      }
    }
  }

  render() {
    return this.state.canMakePayment ? (
      <PaymentRequestButtonElement
        paymentRequest={this.state.paymentRequest}
        className="PaymentRequestButton"
        style={{
          // For more details on how to style the Payment Request Button, see:
          // https://stripe.com/docs/elements/payment-request-button#styling-the-element
          paymentRequestButton: {
            theme: 'light',
            height: '64px'
          }
        }}
      />
    ) : null;
  }
}
export default injectStripe(PaymentRequestForm);
