import React from 'react';
import styles from './styles.module.scss';
import Icons from '../../util/icons';

export type textAreaProps = {
	autoComplete?: string;
	classes?: string[];
	placeholder?: string;
	label?: string;
	name?: string;
	value?: string;
	isError?: boolean;
	disabled?: boolean;
	errorMessage?: string;
	className?: any;
	isRequired?: boolean;
	onChange?: (event: any) => void;
	onBlur?: (event: any) => void;
	onKeyUp?: (event: any) => void;
	onFocus?: (event: any, isFocused: boolean) => void;
}

let id = 1;

export class TextArea extends React.Component<textAreaProps, any> {
	static defaultProps = {
		autoComplete: 'on',
		classes: [],
		placeholder: '',
		label: '',
		name: '',
		value: '',
		disabled: false,
		isError: false,
		errorMessage: '',
		className: '',
		onChange: () => {},
		onBlur: () => {},
		onKeyUp: () => {},
		onFocus: () => {},
		isRequired: false,
	};

	constructor(props: textAreaProps) {
		super(props);
		id += 1;
		const { value } = this.props;
		this.state = {
			id: `TextArea-${id}`,
			value: value || '',
			isFocused: false,
		};
	}

	handleOnFocus = (event: any) => {
		const { isFocused } = this.state;
		this.setState({
			isFocused: true,
		}, () => {
			if (this.props.onFocus) {
				this.props.onFocus(event, isFocused)
			}
		});
	};

	handleChange = (event: any) => {
		const { onChange } = this.props;
		this.setState({
			value: event.target.value,
		});
		if (onChange) {
			onChange(event);
		}
	};

	handleBlur = (event: any) => {
		const { onBlur } = this.props;
		this.setState({
			isFocused: false,
		});
		if (onBlur) {
			onBlur(event);
		}
	};

	handleKeyUp = (event: any) => {
		const { onKeyUp } = this.props;
		if (onKeyUp) {
			onKeyUp(event);
		}
	};

	render() {
		const {
			autoComplete,
			classes = [],
			label,
			placeholder,
			name,
			isError,
			errorMessage,
			value,
			disabled,
			className,
			isRequired,
		} = this.props;

		const { id: uid, value: stateValue } = this.state;
		const inputValue = value || stateValue;

		const textAreaClass = inputValue.length ? `${styles.textArea} ${styles['textArea-filled']}` : `${styles.textArea}`;
		return (
			<div className={classes.join(' ')} style={{ display: 'block', width: '100%' }}>
				{label && (
					<label
						className={styles.label}
						htmlFor={uid}
					>{label}
					</label>
				)}
				<textarea
					autoComplete={autoComplete}
					id={uid}
					placeholder={placeholder}
					name={name}
					disabled={disabled}
					rows={10}
					cols={10}
					onFocus={this.handleOnFocus}
					onChange={this.handleChange}
					onBlur={this.handleBlur}
					onKeyUp={this.handleKeyUp}
					className={[className, textAreaClass].join(' ')}
					required={isRequired}
				>{inputValue}</textarea>
				{isError && <img src={Icons.alert} alt='error' className={styles.textAreaAlert} />}
				{isError && <div className={styles.textAreaError}>{errorMessage}</div> }
			</div>
		);
	}
}
