import React, { Component } from 'react';
import { TextField } from '../../components/TextField';
import { TextArea } from '../../components/TextArea';
import Select from '../../components/Select';
import ScreenTitle from '../../components/ScreenTitle';

import Styles from './styles.module.scss';
import AddressFields from '../../components/AddressFields';

export type fieldTypes =
  | 'text'
  | 'email'
  | 'submit'
  | 'city'
  | 'zip'
  | 'address'
  | 'textarea'
  | 'sectionHeader'
  | 'dropdown'
  | 'sectionBreak';

export type fieldEventType = 'blur';

export type validationType = {
  type: 'required' | 'regex';
  msg: string;
  events: Array<fieldEventType>;
  name?: string;
  regex?: RegExp;
  minDate?: Date;
  maxDate?: Date;
  subfield?: boolean;
};

export type formFieldType = {
  type: fieldTypes;
  name: string;
  label: string;
  keyboard?: string;
  mask?: string;
  value: string;
  validations: Array<validationType>;
  disabled?: boolean;
  options?: string[] | undefined;
  placeholder?: string;
  prefix?: string;
  maxLength?: number;
  isInline?: boolean;
  isInlineIndent?: boolean;
};

export type formFieldProps = {
  index: number;
  field: formFieldType;
  value: string;
  formFieldError: any;
  onChange: (event: any) => void;
  onBlur: (field: formFieldType, value: string) => void;
};

export class FormField extends Component<formFieldProps, any> {
  onChange = (e: any) => {
    const { onChange, field } = this.props;
    const { prefix } = field;
    let fieldValue = e.target.value;
    if (prefix) fieldValue = fieldValue.replace(prefix, '');
    if (onChange) {
      onChange({
        name: field.name,
        value: fieldValue
      });
    }
  };

  onBlur = ({ target }: any) => {
    const { onBlur, field } = this.props;
    const { prefix } = field;
    let fieldValue = target.value;
    if (prefix) fieldValue = fieldValue.replace(prefix, '');

    if (onBlur) {
      onBlur(field, fieldValue);
    }
  };

  renderField = () => {
    const { value, field, formFieldError } = this.props;

    const {
      mask,
      label,
      name,
      type,
      disabled,
      keyboard,
      options,
      placeholder = '',
      prefix,
      maxLength
    } = field;

    const { textFieldClass } = Styles;
    switch (type) {
      case 'text':
      case 'email':
        return (
          <TextField
            className={textFieldClass}
            type={keyboard}
            name={name}
            disabled={disabled}
            label={label}
            mask={mask}
            value={value}
            onChange={this.onChange}
            onBlur={this.onBlur}
            placeholder={placeholder}
            prefix={prefix}
            maxLength={maxLength}
            isError={formFieldError && formFieldError.shouldShowError}
            errorMessage={formFieldError && formFieldError.errorMessage}
          />
        );
      case 'address':
        return (
          <AddressFields
            className={textFieldClass}
            type={keyboard}
            disabled={disabled}
            label={label}
            value={value}
            onChange={this.onChange}
            isError={formFieldError && formFieldError.shouldShowError}
            errorMessage={formFieldError && formFieldError.errorMessage}
            errorFields={formFieldError && formFieldError.errorFields}
          />
        );
      case 'textarea':
        return (
          <TextArea
            className={textFieldClass}
            name={name}
            disabled={disabled}
            label={label}
            value={value}
            onChange={this.onChange}
            onBlur={this.onBlur}
            placeholder={placeholder}
            isError={formFieldError && formFieldError.shouldShowError}
            errorMessage={formFieldError && formFieldError.errorMessage}
          />
        );
      case 'sectionHeader':
        return <ScreenTitle title={label} />;
      case 'sectionBreak':
        return <div style={{ display: 'block' }}></div>;
      case 'dropdown':
        return (
          <Select
            label={label}
            name={name}
            onChange={this.onChange}
            options={options}
            value={value}
            errorMessage={formFieldError && formFieldError.errorMessage}
          />
        );
      default:
        return (
          <TextField
            className={textFieldClass}
            type={keyboard}
            name={name}
            disabled={disabled}
            label={label}
            mask={mask}
            value={value}
            onChange={this.onChange}
            onBlur={this.onBlur}
            placeholder={placeholder}
            prefix={prefix}
          />
        );
    }
  };

  render() {
    const { index, field } = this.props;
    const { isInline, isInlineIndent } = field;
    const { formField, formFieldInline, formFieldInlineIndent } = Styles;

    const containerStyle = [formField];
    if (isInline) containerStyle.push(formFieldInline);
    if (isInline && isInlineIndent) containerStyle.push(formFieldInlineIndent);

    const formFieldContainerClass = containerStyle.join(' ');

    if (field.type === 'sectionBreak') {
      return <div style={{ display: 'block' }}></div>;
    }
    return (
      <div key={index} className={formFieldContainerClass}>
        {this.renderField()}
      </div>
    );
  }
}
