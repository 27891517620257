import React, { useEffect, useState } from 'react';
import Icons, { CaretDownIcon } from '../../util/icons';
import { Label } from '../Label';
import Styles from './styles.module.scss';

export interface SelectProps {
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  id?: string;
  isError?: boolean;
  label?: string;
  name: string;
  onChange?: (v?: any) => void;
  options: any;
  placeholder?: string;
  value?: string;
}

export default function Select(props: SelectProps) {
  const {
    className = '',
    disabled = false,
    errorMessage,
    id,
    isError,
    label,
    name,
    onChange,
    options,
    placeholder = '',
    value
  } = props;

  const [selectedValue, updateSelectedValue] = useState(value);

  useEffect(() => {
    updateSelectedValue(value);
  }, [value]);

  function renderOptions(selectOptions: any) {
    const list = [
      <option key="no-value" disabled value="">
        {placeholder}
      </option>
    ];

    if (selectOptions && selectOptions instanceof Array && selectOptions.length) {
      selectOptions.forEach((option) => {
        list.push(
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        );
      });
    }

    return list;
  }

  return (
    <div className={[Styles.select, className].join(' ')}>
      <Label className={Styles.label} id={name} value={label} />
      <div className={Styles.selectFieldWrapper}>
        <select
          id={id || name}
          disabled={disabled}
          onChange={(e) => {
            updateSelectedValue(e.target.value);
            if (onChange) {
              onChange(e);
            }
          }}
          value={selectedValue}
        >
          {renderOptions(options)}
        </select>
        <CaretDownIcon className={Styles.caretDownIcon} color={'#6B433F'} />
      </div>
      {isError && errorMessage && (
        <img src={Icons.alert} alt="error" className={Styles.textFieldAlert} />
      )}
      {isError && errorMessage && <div className={Styles.textFieldError}>{errorMessage}</div>}
    </div>
  );
}
