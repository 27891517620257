import React from 'react';
import styles from './styles.module.scss';

export type ButtonProps = {
		type?: "button" | "reset" | "submit" | undefined;
		children?: any;
		onClick?: (e: any) => void;
		buttonstyle?: string;
		disabled?: boolean;
		classes?: string[];
}

export default class Button extends React.PureComponent<ButtonProps, any> {
	static defaultProps = {
		type: 'button',
		buttonstyle: 'default',
		disabled: false,
		onClick: () => {},
		classes: [],
		children: [],
	};

	onClickfunction =(e) => {
		const { onClick } = this.props;
		if (onClick) {
			onClick(e);
		}
	};

	render() {
		const {
			type,
			children,
			disabled,
			classes,
		} = this.props;

		const buttonClass = [styles.button];
		if (classes && classes.length) {
			buttonClass.push(...classes);
		}
		return (
			// eslint-disable-next-line react/button-has-type
			<button
				type={type}
				className={buttonClass.join(' ')}
				onClick={this.onClickfunction}
				disabled={disabled}
				tabIndex={0}
			>
				{children}
			</button>
		);
	}
}


/*
disabled props
*/
