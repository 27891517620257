import React from 'react';
import { connect } from 'react-redux';
import { FormField, formFieldType, validationType } from './FormField';
import ScreenTitle from '../../components/ScreenTitle';
import { init, initValues, change, submitErrors, destroyForm } from '../../reducers/formReducer';
import Styles from './styles.module.scss';
import { accessNestedPropertyWithKey } from '../../util/accesssNestedPropertyWithKey';

export type formProps = {
  description?: string;
  destroyFormAction: Function;
  fields: any;
  formsData: any;
  formStatus: any;
  hideSubmit?: boolean;
  initForm: (name: string) => void;
  initFormValues: (fields: Object) => void;
  initialValues?: Object;
  name: string;
  onChange: (e: any) => void;
  onSubmit: (formsData: any) => void;
  renderComponent?: any;
  submitButtonClass?: string;
  submitButtons?: Object;
  submitError: (formField: any, error: any) => void;
  submitType: string;
  title?: string;
};

class Form extends React.Component<formProps, any> {
  static defaultProps = {
    title: undefined,
    description: undefined,
    initialValues: undefined,
    submitButtonClass: '',
    submitType: '',
    submitButtons: {}
  };

  constructor(props: formProps) {
    super(props);
    props.initForm(props.name);
    if (props.initialValues) {
      props.initFormValues(props.initialValues);
    }
  }

  componentWillUnmount = () => {
    const { destroyFormAction, name } = this.props;
    destroyFormAction(name);
  };

  // Returns a list of names of the required fields from the form config validations. Also checks for subfield
  // values, eg. addressDetails.address1, addressDetails.city, etc...
  getRequiredFields = () => {
    const { fields } = this.props;

    const requiredFields: any[] = [];
    fields.forEach((field) => {
      const { validations } = field;

      validations.forEach((rule) => {
        if (field?.name && rule?.type === 'required' && rule?.name) {
          if (rule?.subfield) {
            requiredFields.push(`${field?.name}.${rule?.name}`);
          } else {
            requiredFields.push(field?.name);
          }
        }
      });
    });

    return requiredFields;
  };

  // evaluate if all required fields have a value
  isFormIncomplete = () => {
    const { formsData: formValues } = this.props;

    const requiredFields = this.getRequiredFields();

    // Returns true if any of the required fields are missing from form values. Also checks for the value of
    // nested fields, eg. addressDetails.address1.
    return requiredFields.some((name) => {
      const fieldValue = accessNestedPropertyWithKey(formValues, name);
      return !fieldValue;
    });
  };

  validate = (validation: validationType, value: any, currErrorFields = []) => {
    let errorMessage = '';
    let shouldShowError = false;
    let errorFields: string[] = currErrorFields || [];

    const setError = () => {
      errorMessage = validation.msg;
      shouldShowError = true;
      if (validation.name) errorFields.push(validation.name);
    };

    switch (validation.type) {
      case 'required':
        if (!this.validateRequired(value)) {
          setError();
        }
        break;
      case 'regex':
        if (value && validation.regex && !this.validateRegex(value, validation.regex)) {
          setError();
        }
        break;
      default:
        break;
    }

    return {
      errorMessage,
      shouldShowError,
      errorFields
    };
  };

  validateField = (fieldName: string, value: any, validations: Array<validationType>) => {
    let error;
    const { submitError } = this.props;

    validations.forEach((validation) => {
      if (!validation.subfield && error && error.shouldShowError) {
        return;
      }
      if (validation.name && validation.subfield) {
        error = this.validate(validation, value?.[validation.name], error?.errorFields || []);
      } else {
        error = this.validate(validation, value);
      }
    });

    if (error && error.shouldShowError) {
      submitError(fieldName, error);
      return false;
    }

    return true;
  };

  validateAllFields = () => {
    const { formsData, fields } = this.props;

    let isAllFieldsValid = true;
    fields.forEach((field) => {
      const { name, validations } = field;
      const value = formsData[name];

      const isValidField = this.validateField(name, value, validations);
      isAllFieldsValid = isAllFieldsValid && isValidField;
    });

    return isAllFieldsValid;
  };

  // evaluate if form is valid
  isFormValid = () => {
    const { formStatus } = this.props;

    return Object.keys(formStatus).some(
      (errorField) => formStatus[errorField].shouldShowError === true
    );
  };

  onBlur = (field: formFieldType, value: any) => {
    const validations = field.validations.filter(
      (validation) => validation.events.indexOf('blur') > -1
    );
    this.validateField(field.name, value, validations);
  };

  handleSubmit = async ({ stripeToken, captchaToken }) => {
    const { onSubmit, formsData, formStatus, hideSubmit } = this.props;

    let isInvalid = false;

    if (formsData) {
      isInvalid = !this.validateAllFields();
    }

    if (formStatus) {
      isInvalid = this.isFormValid();
    }

    const formsDataArr = formsData && Object.keys(formsData);
    const formIncomplete = formsDataArr && this.isFormIncomplete();

    if (formsDataArr && !isInvalid && !formIncomplete) {
      if (onSubmit && !!stripeToken.token) {
        onSubmit({ data: formsData, stripeToken, captchaToken });
      }
    }
    if (hideSubmit) {
      return formsDataArr && !isInvalid && !formIncomplete ? formsData : false;
    }
  };

  validateRequired = (value?: any): boolean => {
    const result = !(!value || (value && value.length === 0) || value === '');
    return result;
  };

  validateRegex = (value: any, regex: RegExp): boolean => {
    const result = regex.test(value);
    return result;
  };

  render() {
    const {
      description,
      fields,
      formsData,
      formStatus,
      name,
      onChange,
      renderComponent: RenderComponent,
      title = ''
    } = this.props;

    const { formContainer, formfieldsContainer, formField } = Styles;

    return (
      <form
        id={name}
        className={formContainer}
        name={name}
        // onSubmit={this.handleSubmit}
        autoComplete="on"
      >
          <div className={formfieldsContainer}>
            {(title || description) && (
              <ScreenTitle className={formField} title={title} description={description} />
            )}
            {fields.map((field, index) => {
              const fName = field.name;
              const formFieldError =
                formStatus && formStatus[fName] && formStatus[fName].shouldShowError
                  ? formStatus[field.name]
                  : undefined;
              const value = formsData && formsData[field.name];
              return (
                <FormField
                  key={`${name}-${field.name}-${index}`}
                  field={field}
                  onChange={onChange}
                  index={index}
                  value={value}
                  onBlur={this.onBlur}
                  formFieldError={formFieldError}
                />
              );
            })}
          </div>
          <RenderComponent onSubmit={this.handleSubmit} />
      </form>
    );
  }
}
function mapStateToProps(state, ownProps) {
  return {
    fields: ownProps.config.fields,
    name: ownProps.config.name,
    submitButtons: ownProps.config.submitButtons,
    formsData: state.forms[ownProps.config.name] && state.forms[ownProps.config.name].fields,
    formStatus: state.forms[ownProps.config.name] && state.forms[ownProps.config.name].formStatus
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    initForm: (name) => {
      dispatch(init(name));
    },
    initFormValues: (fields) => {
      dispatch(
        initValues({
          formName: ownProps.config.name,
          fields
        } as any)
      );
    },
    onChange: (e) => {
      const { name: fieldName, value } = e;

      dispatch(
        change({
          formName: ownProps.config.name,
          fieldName,
          value
        } as any)
      );
    },
    submitError: (fieldName, error) => {
      dispatch(
        submitErrors({
          formName: ownProps.config.name,
          fieldName,
          error
        } as any)
      );
    },
    destroyFormAction: (formName) => {
      dispatch(destroyForm(formName));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
