const elementStyles = {
  base: {
    color: '#645756',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    marginTop: '16px',
    border: 0,
    background: 'none',

    ':focus': {
      color: '#645756'
    },

    '::placeholder': {
      color: '#c5b1af'
    },

    ':focus::placeholder': {
      color: '#987672'
    }
  },
  invalid: {
    color: 'red',
    ':focus': {
      color: '#FF5A52'
    },
    '::placeholder': {
      color: '#FF5A52'
    }
  }
};

const elementClasses = {
  focus: 'focus',
  empty: 'empty',
  invalid: 'invalid'
};

export const createOptions = () => ({
  style: elementStyles,
  classes: elementClasses
});
