import React from 'react';
import styles from './styles.module.scss';

interface IPageHeaderProps {
  showHarisumiranHeader?: boolean;
  showToran?: boolean;
}

export const PageHeader = ({ showHarisumiranHeader, showToran }: IPageHeaderProps) => {
  return (
    <header>
      {showHarisumiranHeader ? (
        <div className={styles['header']}>
          <div className={styles['logo_container']}>
            <a className={styles['logo_link']} href="https://www.harisumiran.org/">
              <img src="harisumiran-logo-3x.png" alt="harisumiran logo" />
            </a>
          </div>
        </div>
      ) : null}
      {showToran ? <div className={styles['toran']}></div> : null}
    </header>
  );
};
