import React from 'react';
import styles from './styles.module.scss';

type ScreenTitleProps = {
  title: string;
  description?: string;
  white?: boolean;
  className?: string;
}

export default function ScreenTitle(props: ScreenTitleProps) {
	const {
		title,
		description,
		white,
		className,
	} = props;

	return (
		<div className={[styles['screen-title'], className].join(' ')}>
			{ title && <h4 className={styles.title} style={{ color: white ? 'white' : undefined }}>{title}</h4> }
			{ description
				&& <p className={styles.description} style={{ color: white ? 'white' : undefined }}>{description}</p>
			}
		</div>
	);
}

ScreenTitle.defaultProps = {
	description: '',
	white: false,
	className: '',
};
