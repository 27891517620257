const componentForm: Record<string, string> = {
  administrative_area_level_1: 'long_name', // this is for state
  country: 'short_name',
  locality: 'long_name',
  neighborhood: 'long_name',
  route: 'long_name',
  street_number: 'short_name', // address line 2
  subpremise: 'short_name',
  zip: 'long_name'
};

export const extractUSAddress = (place: any) => {
  const addressComponents: any = place?.address_components;
  const formatted_address = place?.formatted_address;
  const suggestion = {
    street_line: '',
    address2: '',
    city: formatted_address?.split(',')[1].trim() ?? '',
    state: '',
    country: '',
    zip: ''
  };
  if (addressComponents) {
    suggestion.country =
      addressComponents
        .find((a: any) => a.types[0] === 'country')
        ?.[componentForm['country']]?.toLowerCase() ?? '';

    const streetNumber =
      addressComponents.find((a: any) => a.types[0] === 'street_number')?.[
        componentForm['street_number']
      ] ?? '';
    const route =
      addressComponents.find((a: any) => a.types[0] === 'route')?.[componentForm['route']] ?? '';
    const subpremise =
      addressComponents.find((a: any) => a.types[0] === 'subpremise')?.[
        componentForm['subpremise']
      ] ?? '';
    suggestion.street_line = `${streetNumber} ${route}`;
    suggestion.address2 = subpremise;

    if (!suggestion.city) {
      const neighborhood =
        addressComponents.find((a: any) => a.types[0] === 'neighborhood')?.[
          componentForm['neighborhood']
        ] ?? '';

      const locality =
        addressComponents.find((a: any) => a.types[0] === 'locality')?.[
          componentForm['locality']
        ] ?? '';

      suggestion.city = neighborhood || locality || '';
    }

    suggestion.state =
      addressComponents.find((a: any) => a.types[0] === 'administrative_area_level_1')?.[
        componentForm['administrative_area_level_1']
      ] ?? '';
  }
  return suggestion;
};

export const extractNonUSAddress = (place: any) => {
  const addressComponents: any = place?.address_components;
  const suggestion = {
    street_line: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    zip: ''
  };
  if (addressComponents) {
    suggestion.country =
      addressComponents
        .find((a: any) => a.types[0] === 'country')
        ?.[componentForm['country']]?.toLowerCase() ?? '';

    const streetNumber =
      addressComponents.find((a: any) => a.types[0] === 'street_number')?.[
        componentForm['street_number']
      ] ?? '';

    const route =
      addressComponents.find((a: any) => a.types[0] === 'route')?.[componentForm['route']] ?? '';

    let subpremise =
      addressComponents.find((a: any) => a.types[0] === 'subpremise')?.[
        componentForm['subpremise']
      ] ?? '';

    if (!subpremise) {
      subpremise =
        addressComponents.find((a: any) => a.types[0] === 'sublocality_level_1')?.[
          componentForm['subpremise']
        ] ?? '';
    }
    if (!subpremise) {
      subpremise =
        addressComponents.find((a: any) => a.types[0] === 'sublocality')?.[
          componentForm['subpremise']
        ] ?? '';
    }
    suggestion.street_line = `${streetNumber} ${route}`;
    suggestion.address2 = subpremise;

    const neighborhood =
      addressComponents.find((a: any) => a.types[0] === 'neighborhood')?.[
        componentForm['neighborhood']
      ] ?? '';

    const locality =
      addressComponents.find((a: any) => a.types[0] === 'locality')?.[componentForm['locality']] ??
      '';

    const postal_town =
      addressComponents.find((a: any) => a.types[0] === 'postal_town')?.[
        componentForm['locality']
      ] ?? '';

    suggestion.city = neighborhood || locality || postal_town || '';

    suggestion.state =
      addressComponents.find((a: any) => a.types[0] === 'administrative_area_level_1')?.[
        componentForm['administrative_area_level_1']
      ] ?? '';

    suggestion.zip =
      addressComponents.find((a: any) => a.types[0] === 'postal_code')?.[componentForm['zip']] ??
      '';
  }
  return suggestion;
};
