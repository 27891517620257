import { createSlice } from '@reduxjs/toolkit';

const formSlice = createSlice({
	name: 'flowSlice',
	slice: 'flow',
	initialState: {
		step: 1
	},
	reducers: {
		showError(state, { payload }) {
			return {
				formError: payload
			};
		},
		saveTransactionData(state, { payload }) {
			return {
				transaction: {
					...payload
				}
			};
		}
	}
});

const { actions, reducer } = formSlice;

export const { submitPage1, showError, saveTransactionData } = actions;

export default reducer;
