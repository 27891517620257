import { addressValidationAPI } from '../../apis/googlePlacesAPI';
import { doesCountrySupportValidation } from '../../util/doesCountrySupportValidation';

interface IGetVerifiedAddressProps {
  address1?: string;
  address2?: string;
  city?: string;
  country?: string;
  county?: string;
  error?: string;
  state?: string;
  zip?: string;
}

export const getVerifiedAddress = async (unverifiedAddressData: IGetVerifiedAddressProps) => {
  const { address1 = '', address2, city, country, state, zip } = unverifiedAddressData;

  // If address validation is not supported for the country, return the unverified address.
  if (country && !doesCountrySupportValidation(country)) {
    return unverifiedAddressData;
  }

  const addressObject = {
    addressLines: address2 ? [address1, address2] : [address1],
    administrativeArea: state,
    locality: city,
    postalCode: zip,
    regionCode: country
  };

  try {
    const addressValidationResponse = await addressValidationAPI({ address: addressObject });

    const {
      verdict: { validationGranularity, addressComplete },
      address: { postalAddress, missingComponentTypes, unconfirmedComponentTypes },
      uspsData
    } = addressValidationResponse;

    // Ensure the address is complete.
    if (!addressComplete) {
      return { error: `Address is incomplete. Please check ${missingComponentTypes.join(', ')}` };
    }
    // Ensure the address is verified.
    if (validationGranularity === 'OTHER') {
      return {
        error: `Address could not be verified. Please check ${unconfirmedComponentTypes.join(', ')}`
      };
    }

    // If there are no errors, return the verified address.
    return {
      address1: postalAddress.addressLines[0],
      city: postalAddress.locality,
      state: postalAddress.administrativeArea.toLowerCase(),
      zip: postalAddress.postalCode.slice(0, 5),
      county: uspsData?.county ?? '',
      country: postalAddress.regionCode.toLowerCase(),
      error: null
    };
  } catch (error) {
    return { error: 'Address could not be verified.' };
  }
};
