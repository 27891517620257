// `supportsAddressValidation` is a boolean that determines if the country supports address validation from
// the google API. https://developers.google.com/maps/documentation/address-validation/coverage
export const COUNTRIES = [
  {
    abbreviation: 'US',
    name: 'United States',
    supportsAddressValidation: true,
    symbol: '🇺🇸'
  },
  // TODO: Uncomment all countries when international registrations are supported.
  {
    abbreviation: 'AU',
    name: 'Australia',
    symbol: '🇦🇺'
  },
  {
    abbreviation: 'CA',
    name: 'Canada',
    symbol: '🇨🇦'
  },
  {
    abbreviation: 'FR',
    name: 'France',
    symbol: '🇫🇷'
  },
  {
    abbreviation: 'DE',
    name: 'Germany',
    symbol: '🇩🇪'
  },
  {
    abbreviation: 'IN',
    name: 'India',
    symbol: '🇮🇳'
  },
  {
    abbreviation: 'KY',
    name: 'Kenya',
    symbol: '🇰🇪'
  },
  {
    abbreviation: 'MX',
    name: 'Mexico',
    symbol: '🇲🇽'
  },
  {
    abbreviation: 'NZ',
    name: 'New Zealand',
    symbol: '🇳🇿'
  },
  {
    abbreviation: 'PA',
    name: 'Panama',
    symbol: '🇵🇦'
  },
  {
    abbreviation: 'PL',
    name: 'Poland',
    symbol: '🇵🇱'
  },
  {
    abbreviation: 'SL',
    name: 'Scotland',
    symbol: '🏴󠁧󠁢󠁳󠁣󠁴󠁿'
  },
  {
    abbreviation: 'ZA',
    name: 'South Africa',
    symbol: '🇿🇦'
  },
  {
    abbreviation: 'CH',
    name: 'Switzerland',
    symbol: '🇨🇭'
  },
  {
    abbreviation: 'TZ',
    name: 'Tanzania',
    symbol: '🇹🇿'
  },
  {
    abbreviation: 'UG',
    name: 'Uganda',
    symbol: '🇺🇬'
  },
  {
    abbreviation: 'UAE',
    name: 'United Arab Emirates',
    symbol: '🇦🇪'
  },
  {
    abbreviation: 'GB',
    name: 'United Kingdom',
    symbol: '🇬🇧'
  }
];
