import DONATION_TYPES from '../constants/donationTypes';
import CENTERS from '../constants/centers';

type Validation = {
  name?: string;
  type: string;
  msg: string;
  events: string[];
  regex?: RegExp;
  subfield?: boolean;
};

interface FieldType {
  type: string;
  name: string;
  label: string;
  placeholder?: string;
  validations?: Validation[];
  keyboard?: string;
  mask?: string | null;
  initialValue?: string | null;
  default?: string;
  isInline?: boolean;
  isInlineIndent?: boolean;
}

const COMMON_FIELDS: FieldType[] = [
  {
    type: 'sectionHeader',
    name: 'personalInfo',
    label: 'Personal Info',
    validations: []
  },
  {
    type: 'text',
    name: 'firstName',
    label: 'First name',
    keyboard: 'text',
    mask: null,
    initialValue: null,
    isInline: true,
    validations: [
      {
        type: 'required',
        msg: 'First Name is required',
        events: ['blur']
      },
      {
        type: 'regex',
        regex: /^[a-zA-Z \-_.äöüß]+$/,
        msg: 'Please Enter Valid First Name',
        events: ['blur']
      }
    ]
  },
  {
    type: 'text',
    name: 'lastName',
    label: 'Last name',
    keyboard: 'text',
    mask: null,
    initialValue: null,
    isInline: true,
    isInlineIndent: true,
    validations: [
      {
        type: 'required',
        msg: 'Last Name is required',
        events: ['blur']
      }
    ]
  },
  {
    type: 'email',
    name: 'email',
    label: 'Email',
    keyboard: 'email',
    placeholder: 'Ex. example@mail.com',
    mask: null,
    isInline: true,
    validations: [
      {
        type: 'required',
        msg: 'Email is required',
        events: ['blur']
      },
      {
        type: 'regex',
        // eslint-disable-next-line
        regex:
          /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i,
        msg: 'Invalid Email Address',
        events: ['blur']
      }
    ]
  },
  {
    type: 'text',
    name: 'phone',
    label: 'Mobile number',
    keyboard: 'text',
    mask: '999-999-9999',
    placeholder: 'Ex. 123-369-9999',
    isInline: true,
    isInlineIndent: true,
    validations: [
      {
        type: 'required',
        msg: 'Mobile Number is required',
        events: ['blur']
      },
      {
        type: 'regex',
        regex: /^[2-9]\d{2}-\d{3}-\d{4}$/,
        msg: 'Invalid Mobile Number',
        events: ['blur']
      }
    ]
  },
  {
    type: 'address',
    name: 'addressDetails',
    label: 'Address',
    keyboard: 'text',
    validations: [
      {
        name: 'country',
        type: 'required',
        msg: 'Country is required',
        events: ['submit'],
        subfield: true
      },
      {
        name: 'address1',
        type: 'required',
        msg: 'Address 1 is required',
        events: ['submit'],
        subfield: true
      },
      {
        name: 'city',
        type: 'required',
        msg: 'City is required',
        events: ['submit'],
        subfield: true
      },
      {
        name: 'state',
        type: 'required',
        msg: 'State is required',
        events: ['submit'],
        subfield: true
      },
      {
        name: 'zip',
        type: 'required',
        msg: 'Zip code is required',
        events: ['submit'],
        subfield: true
      }
    ]
  }
];

export const TEMPLATE_DONATION_FORM = {
  name: 'template_donation_form',
  fields: [
    ...COMMON_FIELDS,
    {
      type: 'sectionHeader',
      name: '',
      label: 'Donation Info',
      validations: []
    },
    {
      type: 'dropdown',
      name: 'center',
      label: 'Center',
      isInline: true,
      options: CENTERS.map((center) => ({
        name: center.name,
        value: center.value
      })),
      default: CENTERS[0].value,
      validations: []
    },
    {
      type: 'text',
      name: 'amount',
      label: 'Donation Amount (Minimum $51*)',
      placeholder: 'Ex. $51.00',
      prefix: '$',
      keyboard: 'text',
      mask: null,
      initialValue: null,
      isInline: true,
      maxLength: 9,
      validations: [
        {
          type: 'required',
          msg: 'Donation Amount is required',
          events: ['blur']
        },
        {
          type: 'regex',
          regex: /^(1[1-9]|[2-9]\d{1,4}|\d{3,5})(\.\d{1,2})?$/,
          msg: 'Please Enter Valid Donation Amount',
          events: ['blur']
        }
      ]
    },
    {
      type: 'dropdown',
      name: 'sevaFor',
      label: 'Seva For',
      isInline: true,
      isInlineIndent: true,
      options: DONATION_TYPES.map((donationType) => ({
        name: donationType.name,
        value: donationType.value
      })),
      default: DONATION_TYPES[0].value,
      validations: []
    },
    {
      type: 'text',
      name: 'note',
      label: 'Note (Optional)',
      placeholder: 'Please write notes about the donation',
      mask: null,
      initialValue: null,
      validations: []
    }
  ]
};

export const TX_DONATION_FORM = {
  name: 'template_donation_form',
  fields: [
    ...COMMON_FIELDS,
    {
      type: 'sectionHeader',
      name: '',
      label: 'Donation Info',
      validations: []
    },
    {
      type: 'text',
      name: 'amount',
      label: 'Donation Amount (Minimum $51*)',
      placeholder: '$51.00',
      prefix: '$',
      keyboard: 'text',
      mask: null,
      initialValue: null,
      isInline: true,
      maxLength: 9,
      validations: [
        {
          type: 'required',
          msg: 'Donation Amount is required',
          events: ['blur']
        },
        {
          type: 'regex',
          regex: /^(1[1-9]|[2-9]\d{1,4}|\d{3,5})(\.\d{1,2})?$/,
          msg: 'Please Enter Valid Donation Amount',
          events: ['blur']
        }
      ]
    },
    {
      type: 'dropdown',
      name: 'sevaFor',
      label: 'Seva For',
      isInline: true,
      isInlineIndent: true,
      options: DONATION_TYPES.map((donationType) => ({
        name: donationType.name,
        value: donationType.value
      })),
      default: DONATION_TYPES[0].value,
      validations: []
    },
    {
      type: 'text',
      name: 'note',
      label: 'Note (Optional)',
      placeholder: 'Please write notes about the donation',
      mask: null,
      initialValue: null,
      validations: []
    }
  ]
};
