import { combineReducers } from 'redux';
import formReducer from './formReducer';
import flowReducer from './flowReducer';

const reducers = {
	forms: formReducer,
	flow: flowReducer,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
