const CENTERS = [
  {
    name: 'Harisumiran New Jersey',
    value: 'NJ'
  },
  {
    name: 'Harisumiran Houston',
    value: 'TX'
  },
  {
    name: 'Harisumiran Chicago',
    value: 'IL'
  }
];

export default CENTERS;
