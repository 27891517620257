import DONATION_TYPES from '../constants/donationTypes';

export const emptyMember = {
  phone: '',
  email: '',
  addressDetails: {
    country: 'us'
  },
  sevaFor: DONATION_TYPES[0].value,
  center: 'NJ',
  note: ''
};
