const GOOGLE_ADDRESS_VALIDATION_API = process.env.REACT_APP_GOOGLE_ADDRESS_VALIDATION_API ?? '';

interface IAddressValidationAPIProps {
  address: {
    addressLines?: string[];
    administrativeArea?: string;
    locality?: string;
    postalCode?: string;
    regionCode?: string;
  };
}

export const addressValidationAPI = async (address: IAddressValidationAPIProps) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const raw = JSON.stringify(address);

  const requestOptions = {
    method: 'POST',
    headers: headers,
    body: raw,
    redirect: 'follow'
  };

  const res = await fetch(GOOGLE_ADDRESS_VALIDATION_API, requestOptions as RequestInit);
  const resText = await res.text();

  if (res.status === 200) {
    const data = JSON.parse(resText);
    if (data.result) {
      return data.result;
    } else {
      throw new Error('Address validation error.');
    }
  } else if (res.status === 400) {
    const { message } = JSON.parse(resText);
    throw new Error(message);
  } else {
    throw new Error(resText);
  }
};
