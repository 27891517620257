const DONATION_TYPES = [
  {
    name: 'Mandir Utsav Seva',
    value: 'MANDIR UTSAV SEVA'
  },
  {
    name: 'Mahapooja Seva',
    value: 'MAHAPOOJA'
  },

  {
    name: 'Thakorji’s Thaal Seva',
    value: 'THAAL'
  },
  {
    name: 'Annakut Mahotsav',
    value: 'ANNAKUT MAHOTSAV'
  },
  {
    name: 'Rasoi Seva',
    value: 'RASOI SEVA'
  },
  {
    name: 'Shibir Seva',
    value: 'SHIBIR FEE'
  },
  {
    name: '10-20 Seva',
    value: '10-20'
  },
  {
    name: 'Other',
    value: 'OTHER'
  }
];

export default DONATION_TYPES;
