import { useEffect, useState } from 'react';
import { TextField } from '../TextField';
import Select from '../Select';
import US_STATES from '../../constants/states';
import GoogleAutoComplete from '../GoogleAutoComplete';
import Styles from './styles.module.scss';
import { getVerifiedAddress } from './googlePlaces';
import { COUNTRIES } from '../../constants/countries';
import React from 'react';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_KEY;
interface IAddressFields {
  address1: string;
  address2?: string;
  city: string;
  country: string;
  county?: string;
  state: string;
  zip: string;
}

export const defaultFields: IAddressFields = {
  address1: '',
  address2: '',
  city: '',
  country: 'us',
  county: '',
  state: '',
  zip: ''
};

interface onChangeProps {
  target: {
    value: IAddressFields;
  };
}

export interface AddressFieldsProps {
  addressErrors?: any;
  className?: string;
  disabled?: boolean;
  errorFields?: string[];
  errorMessage?: string;
  formName?: string;
  isError?: boolean;
  label?: string;
  onChange?: (value: onChangeProps) => void;
  type?: string;
  value?: any;
}

const AddressFields = ({
  addressErrors,
  className,
  disabled = false,
  errorFields = [],
  errorMessage,
  isError,
  label,
  onChange,
  type,
  value
}: AddressFieldsProps) => {
  const [address, setAddress] = useState(defaultFields);

  useEffect(() => {
    if (value && !address.address1) {
      setAddress(value);
    }
  }, [value, address.address1]);

  const handleSuggestedAddressClick = async (suggestion: any) => {
    const fullAddressObject = await getVerifiedAddress({
      address1: suggestion.street_line,
      address2: suggestion.address2,
      city: suggestion.city,
      state: suggestion.state,
      zip: suggestion.zip,
      country: suggestion.country
    });

    if (fullAddressObject.error) {
      return;
    }
    const address = {
      address1: fullAddressObject.address1,
      address2: '',
      city: fullAddressObject.city,
      country: fullAddressObject.country,
      county: fullAddressObject.county,
      state: fullAddressObject.state,
      zip: fullAddressObject.zip
    };
    setAddress(address);
    if (onChange) {
      onChange({ target: { value: address } });
    }
  };

  const handleChange = (type: string, value: string) => {
    if (onChange) {
      let updateAddress = { ...address };
      switch (type) {
        case 'country':
          updateAddress = { ...defaultFields };
          updateAddress.country = value;
          break;
        case 'address1':
          updateAddress.address1 = value;
          break;
        case 'address2':
          updateAddress.address2 = value;
          break;
        case 'city':
          updateAddress.city = value;
          break;
        case 'county':
          updateAddress.county = value;
          break;
        case 'state':
          updateAddress.state = value;
          break;
        case 'zip':
          updateAddress.zip = value;
      }
      setAddress({
        ...updateAddress
      });
      onChange({ target: { value: updateAddress } });
    }
  };

  const { address1, address2, city, country = 'us', county, state, zip } = address;
  // Disable the state field if a country is not selected.
  const showAdditionalAddressField = !!country;
  const zipCodeLabel = country.toLowerCase() === 'us' ? 'Zip Code' : 'Postal Code';
  return (
    <>
      <div className={[Styles['address-fields'], className].join(' ')}>
        <Select
          className={Styles['address-field-state']}
          disabled={disabled}
          errorMessage={'Country is required'}
          id="country_select"
          isError={errorFields.includes('country')}
          label={label ? 'Country' : ''}
          name={'country'}
          onChange={(e) => handleChange('country', e.target.value)}
          options={getOptionsForCountry()}
          placeholder="Select a country"
          value={country}
        />
      </div>
      {showAdditionalAddressField ? (
        <>
          <div className={[Styles['address-fields-inline'], className].join(' ')}>
            <GoogleAutoComplete
              apiKey={GOOGLE_API_KEY ?? ''}
              className={className}
              disabled={disabled}
              handleChange={(v) => handleChange('address1', v)}
              label={label ? 'Address 1' : ''}
              name={'addressLine1'}
              onLoadFailed={(error) => {
                console.error('~~~~ Errorr Google Autocomplete : ', error);
              }}
              onSuggestionClick={(v) => handleSuggestedAddressClick(v)}
              placeholder={'Address 1'}
              value={address1 || ''}
              country={country}
              errorMessage={'Address 1 is required'}
              isError={errorFields.includes('address1')}
            />
            <TextField
              className={className}
              type={type}
              name={'addressLine2'}
              disabled={disabled}
              label={label ? 'Address 2' : ''}
              value={address2 || ''}
              onChange={(e) => handleChange('address2', e.target.value)}
              placeholder="Address 2"
            />
          </div>
          <div className={[Styles['address-fields-inline']].join(' ')}>
            <TextField
              className={className}
              disabled={disabled}
              errorMessage={'City is required'}
              isError={errorFields.includes('city')}
              label={label ? 'City' : ''}
              name={'city'}
              onChange={(e) => handleChange('city', e.target.value)}
              placeholder="City"
              value={city || ''}
            />
            <TextField
              className={className}
              disabled={disabled}
              label=""
              name={'county'}
              onChange={(e) => handleChange('county', e.target.value)}
              type="hidden"
              value={county || ''}
            />
            {address?.country?.toLowerCase() === 'us' ? (
              <Select
                className={Styles['address-field-state']}
                disabled={disabled}
                errorMessage={'State is required'}
                id="state_select"
                isError={errorFields.includes('state')}
                label={label ? 'State' : ''}
                name={'state'}
                onChange={(e) => handleChange('state', e.target.value)}
                options={getOptionsForState()}
                placeholder="Select a state"
                value={state ? state : ''}
              />
            ) : (
              <TextField
                className={className}
                disabled={disabled}
                errorMessage={'State is required'}
                isError={errorFields.includes('state')}
                label={label ? 'State' : ''}
                name={'state'}
                onChange={(e) => handleChange('state', e.target.value)}
                placeholder="State"
                value={state || ''}
              />
            )}
          </div>
          <div className={[Styles['address-fields-inline']].join(' ')}>
            <TextField
              className={className}
              disabled={disabled}
              errorMessage={`${zipCodeLabel} is required`}
              isError={errorFields.includes('zip')}
              label={label ? zipCodeLabel : ''}
              mask={address?.country?.toLowerCase() === 'us' ? '99999' : undefined}
              name={'zip-code'}
              onChange={(e) => handleChange('zip', e.target.value)}
              placeholder={zipCodeLabel}
              value={zip || ''}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

function getOptionsForState() {
  return US_STATES.map((state) => ({
    name: state.name,
    value: state.abbreviation.toLowerCase()
  }));
}

function getOptionsForCountry() {
  return COUNTRIES.map((country) => ({
    name: `${country.symbol} ${country.name}`,
    value: country.abbreviation.toLowerCase()
  }));
}

export default AddressFields;
