import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Loader, LoaderOptions } from '@googlemaps/js-api-loader';
import { TextField } from '../TextField';
import { extractNonUSAddress, extractUSAddress } from './utils';

export type GoogleAutocompleteHandle = {
  getSessionToken: () => google.maps.places.AutocompleteSessionToken | undefined;
  refreshSessionToken: () => void;
};

export interface LatLng {
  lat: number;
  lng: number;
}

export interface GoogleAutocompleteProps {
  apiKey?: string;
  apiOptions?: Partial<LoaderOptions>;
  className?: string;
  country?: string;
  disabled?: boolean;
  errorMessage?: string;
  formName?: string;
  handleBlur?: (event: any) => void;
  handleChange?: (value: string) => void;
  isError?: boolean;
  label?: string;
  name?: string;
  onLoadFailed?: (error: Error) => void;
  onSuggestionClick?: (suggestion: any) => void;
  placeholder?: string;
  value?: string;
}

function handleScroll() {
  //hide google auto complete container
  document.querySelector('.pac-container')?.classList.add('display-none');
}

const GoogleAutocomplete: React.ForwardRefRenderFunction<
  GoogleAutocompleteHandle,
  GoogleAutocompleteProps
> = ({
  apiKey = '',
  apiOptions = {},
  className,
  country = '',
  disabled = false,
  errorMessage = '',
  handleBlur,
  handleChange,
  isError = false,
  label,
  name,
  onLoadFailed = console.error,
  onSuggestionClick,
  placeholder = 'Address',
  value = ''
}: GoogleAutocompleteProps): React.ReactElement => {
  const autoCompleteRef = useRef<HTMLInputElement>(null);
  const autoCompleteMounted = useRef(false);

  const [autoCompleteControl, setAutoCompleteControl] = useState<
    google.maps.places.Autocomplete | undefined
  >(undefined);

  const initializeService = () => {
    if (!window.google)
      throw new Error('[react-google-places-autocomplete]: Google script not loaded');
    if (!window.google.maps)
      throw new Error('[react-google-places-autocomplete]: Google maps script not loaded');
    if (!window.google.maps.places)
      throw new Error('[react-google-places-autocomplete]: Google maps places script not loaded');

    if (autoCompleteRef && autoCompleteRef.current) {
      setAutoCompleteControl(
        new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
          types: ['address'],
          componentRestrictions: { country }
        })
      );
    }
  };

  const handleSuggestedAddressClick = useCallback(async () => {
    const place = autoCompleteControl?.getPlace();
    const addressComponents: any = place?.address_components;
    let suggestion = {
      street_line: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      zip: ''
    };
    if (addressComponents) {
      const country =
        addressComponents.find((a: any) => a.types[0] === 'country')?.short_name?.toLowerCase() ??
        '';
      suggestion = country === 'us' ? extractUSAddress(place) : extractNonUSAddress(place);
    }
    if (onSuggestionClick) {
      onSuggestionClick(suggestion);
    }
  }, [autoCompleteControl, onSuggestionClick]);

  useEffect(() => {
    const init = async () => {
      try {
        if (!window.google || !window.google.maps || !window.google.maps.places) {
          await new Loader({
            apiKey,
            ...{ libraries: ['places'], ...apiOptions }
          }).load();
        }
        initializeService();
      } catch (error: any) {
        onLoadFailed(error as Error);
      }
    };
    if (apiKey) init();
    else initializeService();

    // hide google auto complete container on scroll
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (autoCompleteControl !== undefined && autoCompleteMounted.current === false) {
      autoCompleteControl.setFields(['address_components', 'formatted_address']);
      autoCompleteControl.addListener('place_changed', handleSuggestedAddressClick);
      autoCompleteMounted.current = true;
    }
  }, [autoCompleteControl, autoCompleteMounted, handleSuggestedAddressClick]);

  useEffect(() => {
    if (country) {
      autoCompleteControl?.setComponentRestrictions({ country });
    }
  }, [autoCompleteControl, country]);

  const handleInputChange = (event: any) => {
    //Show google auto complete container
    document.querySelector('.pac-container')?.classList.remove('display-none');

    if (handleChange) {
      handleChange(event.target.value);
    }
  };

  const handleInputBlur = (event: any) => {
    if (handleBlur) {
      handleBlur(event);
    }
  };

  return (
    <TextField
      className={className}
      disabled={disabled}
      errorMessage={errorMessage}
      isError={isError}
      label={label ? 'Address 1' : ''}
      name={name}
      onBlur={handleInputBlur}
      onChange={handleInputChange}
      placeholder={placeholder}
      ref={autoCompleteRef}
      type="address"
      value={value}
    />
  );
};

export default forwardRef(GoogleAutocomplete);
