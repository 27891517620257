// Access a nested property of an object using a key string, ie 'a.b.c' gives you a['b']['c'].
export const accessNestedPropertyWithKey = (obj: Record<string, any>, keyString: string) => {
  const keys = keyString.split('.');
  let value = obj;

  for (const key of keys) {
    if (value && typeof value === 'object' && key in value) {
      value = value[key];
    } else {
      return undefined;
    }
  }

  return value;
};
