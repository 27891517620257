export const getStripeAPIKeyByLocation = (location: string): string => {
  switch (location) {
    case 'IL':
      return process.env.REACT_APP_STRIPE_API_KEY_IL as string;
    case 'TX':
      return process.env.REACT_APP_STRIPE_API_KEY_TX as string;
    case 'NJ':
    default:
      return process.env.REACT_APP_STRIPE_API_KEY_NJ as string;
  }
};
