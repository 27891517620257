import React from 'react';

export type labelProps = {
    className?: string;
	id: any,
	value: any,
	style?: any,
}

export const Label = (props: labelProps) => {
	const { style = {}, id, value, className } = props;
	return (
		<label style={style} htmlFor={id} className={className}>
			{value}
		</label>
	);
};

Label.defaultProps = {
	style: {},
};
