import { createSlice } from '@reduxjs/toolkit';
import dotProp from 'dot-prop-immutable';

const formSlice = createSlice({
	name: 'formSlice',
	slice: 'forms',
	initialState: {},
	reducers: {
		init(state, action) {
			return {
				[action.payload]: {}
			};
		},
		destroyForm: (state, { payload: data }) => {
			const stateCopy = Object.assign({}, state);
			delete stateCopy[data];
			return stateCopy;
		},
		initValues(state, { payload: { formName, fields } }) {
			if (!fields) {
				return Object.assign({}, state, {
					[formName]: {
						...state[formName],
						fields: {}
					}
				});
			}
			const formFields = state[formName].fields;
			return Object.assign({}, state, {
				[formName]: {
					fields: {
						...formFields,
						...fields
					}
				}
			});
		},
		change(state, { payload: { formName, fieldName, value } }) {
			const formFields = state[formName].fields;
			const { formStatus } = state[formName];
			const newFormFields = formFields
				? dotProp.set(formFields, fieldName, value)
				: { [fieldName]: value };
			const newStatus = dotProp.delete(formStatus, fieldName);

			return Object.assign({}, state, {
				[formName]: {
					fields: newFormFields,
					formStatus: newStatus
				}
			});
		},
		addressUpdate(state, { payload: { formName, address } }) {
			const formFields = state[formName].fields;
			const newFormFields = {
				...formFields,
				address
			};

			return Object.assign({}, state, {
				[formName]: {
					fields: newFormFields
				}
			});
		},
		resetFormStatus(state, action) {
			return Object.assign({}, state, {
				[action.payload]: {
					...state[action.payload],
					formStatus: {}
				}
			});
		},
		submitErrors(state, { payload: { formName, fieldName, error } }) {
			const { formStatus } = state[formName];
			let newForm = { ...formStatus };

			if (fieldName) {
				newForm = {
					...formStatus,
					[fieldName]: error
				};
			}

			return Object.assign({}, state, {
				[formName]: {
					...state[formName],
					formStatus: newForm
				}
			});
		}
	}
});

const { actions, reducer } = formSlice;

export const {
	init,
	initValues,
	change,
	submitErrors,
	resetFormStatus,
	destroyForm,
	addressUpdate
} = actions;

export default reducer;
