/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component, useState } from 'react';
import {
  StripeProvider,
  Elements,
  CardExpiryElement,
  CardNumberElement,
  CardCVCElement,
  injectStripe
} from 'react-stripe-elements';
import { connect } from 'react-redux';

import ReCAPTCHA from 'react-google-recaptcha';
import styles from './styles.module.scss';
import Button from '../../components/Button';
import { createOptions } from './util';
import Icons from '../../util/icons';
import { WebsiteType } from '../../constants/websiteTypes';
import PaymentRequestForm from './PaymentRequestForm';

export const MakePaymentScreenDetails = {
  title: 'Payment Info'
};

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;

class Form extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      buttonDisabled: false
    };
  }

  handleSubmit = async (ev) => {
    ev.preventDefault();
    const { stripe, onSubmit } = this.props;
    if (stripe) {
      this.setState((state) => {
        return {
          buttonDisabled: true
        };
      });
      stripe
        .createToken()
        .then((stripeToken) => {
          onSubmit({ stripeToken });
        })
        .then(() => this.setState({ buttonDisabled: false }));
    } else {
      console.error("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    const { buttonDisabled } = this.state;
    const { hideSubmit, errorString, onUpdateCaptchaToken } = this.props;

    return (
      <div className={styles['make-payment-screen']}>
        <div className={styles['cc-control']}>
          <div className={styles['cc-number']}>
            <label className={styles.label}>
              Card number
              <CardNumberElement id="ccNumber" {...createOptions()} />
            </label>
          </div>
          <div className={styles['exp-dt']}>
            <label className={styles.label}>
              Exp Date
              <CardExpiryElement id="ccExpiry" {...createOptions()} />
            </label>
          </div>
          <div className={styles.cvv}>
            <label className={styles.label}>
              CVV
              <CardCVCElement id="ccCVV" {...createOptions()} />
            </label>
          </div>
          <div className={styles['postal-code']}></div>
          {errorString && errorString !== '' && (
            <div className={styles['error-box']}>{errorString}</div>
          )}
        </div>

        <div className={styles.recaptcha}>
          <ReCAPTCHA onChange={onUpdateCaptchaToken} sitekey={RECAPTCHA_SITE_KEY} tabIndex={0} />
        </div>
        {!hideSubmit && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
            <Button disabled={buttonDisabled} onClick={this.handleSubmit}>
              Submit Donation
            </Button>
          </div>
        )}

        {process.env.REACT_APP_DONATE_FORM_TYPE === WebsiteType.HARISUMIRAN && (
          <div className={styles.legalDisclaimer}>
            <span>
              Yogi Divine Society d/b/a Harisumiran is a 501(c) (3) non-profit organization in
              accordance with the standards and regulations of the Internal Revenue Service (IRS).
              Federal Tax ID# 22-2937318.
            </span>
          </div>
        )}
        <div className={styles.securePaymentContainer}>
          <img
            src={Icons.stripeBadge}
            alt="powered by stripe"
            className={styles['secure-payment']}
          />
        </div>
      </div>
    );
  }
}

const PaymentForm = injectStripe(Form);

type PaymentPropType = {
  onTokenReady?: Function;
  apiKey: string;
  hideSubmit?: boolean;
  onSubmit?: Function;
  errorString?: string;
  formsData: any;
  config: any;
  center?: 'NJ' | 'TX' | 'IL';
};

const PaymentFormContainer = (props: PaymentPropType) => {
  const { apiKey, onTokenReady, hideSubmit, formsData, onSubmit, errorString, center } = props;
  const [captchaToken, setCaptchaToken] = useState(null);

  const onUpdateCaptchaToken = (captchaToken: any) => {
    setCaptchaToken(captchaToken);
  };

  return (
    <StripeProvider apiKey={apiKey || 'null'}>
      <Elements>
        <>
          {center === 'NJ' ? (
            <PaymentRequestForm
              amt={formsData.amount}
              onSubmit={(data: any) => {
                if (onSubmit) {
                  onSubmit({ ...data, captchaToken });
                }
              }}
            />
          ) : null}
          <PaymentForm
            errorString={errorString}
            onTokenReady={onTokenReady}
            hideSubmit={hideSubmit}
            onSubmit={(data: any) => {
              if (onSubmit) {
                onSubmit({ ...data, captchaToken });
              }
            }}
            onUpdateCaptchaToken={onUpdateCaptchaToken}
          />
        </>
      </Elements>
    </StripeProvider>
  );
};

PaymentFormContainer.defaultProps = {
  onTokenReady: () => {}
};

const mapStateToProps = (state, ownProps) => {
  return {
    formsData: state.forms[ownProps.config.name] && state.forms[ownProps.config.name].fields
  };
};

export default connect(mapStateToProps, null)(PaymentFormContainer);
