import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { WebsiteType } from '../../constants/websiteTypes';
import styles from './styles.module.scss';

const DONATE_FORM_TYPE = process.env.REACT_APP_DONATE_FORM_TYPE;

let THANK_YOU_TEMPLATE = Harisumiran_ThankYou;
if (DONATE_FORM_TYPE === WebsiteType.ACC) {
  THANK_YOU_TEMPLATE = ACC_ThankYou;
}

function Harisumiran_ThankYou({ transaction }) {
  let history = useHistory();
  const location = useLocation<{ center?: string }>();
  const center = location.state.center?.toUpperCase() || 'NJ';

  if (!transaction) {
    history.push('/');
  }

  return (
    <div className={styles.thankYou}>
      <div>Reference Number: {transaction}.</div>
      <div>Thank you for your continued support. </div>
      <div>Your confirmation receipt will be emailed to you shortly.</div>
    </div>
  );
}

function ACC_ThankYou({ transaction }) {
  let history = useHistory();

  if (!transaction) {
    history.push('/');
  }

  return (
    <div className={styles.thankYou}>
      <h4>
        On behalf of Atmiya Care Charities Inc., we thank you for your continued support of this
        organization.
      </h4>
      <h4>
        Please be on the lookout for your confirmation number ({transaction}) in your email. Thank
        you
      </h4>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    transaction: state.flow.transaction ? state.flow.transaction.transactionId : null
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export const ThankYou = connect(mapStateToProps, mapDispatchToProps)(THANK_YOU_TEMPLATE);
