const API_DOMAIN = process.env.REACT_APP_API_DOMAIN as string;

interface CardToken {
  id: string;
}

interface DonationType {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone: string;
  note: string;
  amount: string;
  sevaFor: string;
  paymentType: string;
  center: string;
  cardToken: CardToken;
  reCaptchaToken: string;
}

export const donate = async (donationData: DonationType) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const raw = JSON.stringify({ ...donationData });

  const requestOptions = {
    method: 'POST',
    headers: headers,
    body: raw,
    redirect: 'follow'
  };
  const URL = `${API_DOMAIN}/api/donate/online` as string;

  const res = await fetch(URL, requestOptions as RequestInit);
  const resText = await res.text();
  if (res.status === 200) {
    return JSON.parse(resText);
  } else if (res.status === 400) {
    const { message } = JSON.parse(resText);
    throw new Error(message);
  } else {
    throw new Error(resText);
  }
};
